import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Button from "../../../shared/NewForms/Button";
import OrderStepper from "../../../shared/OrderStepper";
import BankStep from "./Steps/BankStep";
import PersonalStep from "./Steps/PersonalStep";
import QrCodeStep from "./Steps/QrCodeStep";
import VehicleStep from "./Steps/VehicleStep";
import useStore, {
    FormStepEnum,
    ThgPartialForm,
    ThgPartialFormTypes,
} from "./store";
import FormSummary from "./Summary";

const stepTotalCount = 4;

const StepButtonContainer = styled.div`
    display: flex;
    gap: 25px;
    justify-content: space-between;
    margin-top: 10px;
`;

const Container = styled.div`
    margin-bottom: 100px;
`;

export const Error = styled.p`
    font-size: small;
    ${({ theme }) => css`
        color: ${theme.colors.red.toString()};
    `}
`;

export const preventEnterButton = (event: React.KeyboardEvent) => {
    if (event.code === "Enter") {
        return event.preventDefault();
    }
};

const ThgForm = () => {
    const [
        currentStep,
        setCurrentStep,
        setPartialForm,
        isSummaryEdit,
        setIsSummaryEdit,
    ] = useStore((state) => [
        state.currentStep,
        state.setCurrentStep,
        state.setPartialForm,
        state.isSummaryEdit,
        state.setIsSummaryEdit,
    ]);

    const handlePreviousStep = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (currentStep === 1) {
            return;
        }
        if (isSummaryEdit) {
            return setCurrentStep(FormStepEnum.SUMMARY);
        }
        setCurrentStep(currentStep - 1);
    };

    const handlePartialForm = (
        key: ThgPartialFormTypes,
        data: ThgPartialForm
    ): void => {
        setPartialForm(key, data);
        //if the user has reached the last step set enable Edit mode.
        if (currentStep + 1 === FormStepEnum.SUMMARY) {
            setIsSummaryEdit(true);
        }
        //Redirect to summary if the user is editing.
        if (isSummaryEdit && currentStep < FormStepEnum.SUMMARY) {
            return setCurrentStep(FormStepEnum.SUMMARY);
        }
        setCurrentStep(currentStep + 1);
        window && window.scrollTo(0, 0);
    };

    const handleSummaryEdit = (step: number) => {
        setCurrentStep(step);
        window && window.scrollTo(0, 0);
    };

    if (currentStep === FormStepEnum.SUMMARY) {
        window && window.scrollTo(0, 0);
        return (
            <Container>
                <FormSummary onSummaryEdit={handleSummaryEdit} />
            </Container>
        );
    }

    return (
        <Container>
            {!isSummaryEdit && (
                <OrderStepper
                    steps={stepTotalCount}
                    currentStep={currentStep}
                />
            )}

            {currentStep === FormStepEnum.QR_CODE && (
                <QrCodeStep>
                    <FormControls isDisabledBack={true} />
                </QrCodeStep>
            )}
            {currentStep === FormStepEnum.PERSONAL && (
                <PersonalStep onFormSubmitted={handlePartialForm}>
                    <FormControls onBack={handlePreviousStep} />
                </PersonalStep>
            )}
            {currentStep === FormStepEnum.VEHICLE && (
                <VehicleStep onFormSubmitted={handlePartialForm}>
                    <FormControls onBack={handlePreviousStep} />
                </VehicleStep>
            )}
            {currentStep === FormStepEnum.BANK && (
                <BankStep onFormSubmitted={handlePartialForm}>
                    <FormControls onBack={handlePreviousStep} />
                </BankStep>
            )}
        </Container>
    );
};

interface iFormControlProps {
    onBack?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isDisabledBack?: boolean;
    isDisabledSubmit?: boolean;
    submitText?: string;
    backText?: string;
}
const FormControls = ({
    onBack,
    isDisabledBack = false,
    isDisabledSubmit = false,
    submitText = "Weiter",
    backText = "Zurück",
}: iFormControlProps) => {
    const [isSummaryEdit, loading] = useStore((state) => [
        state.isSummaryEdit,
        state.loading,
    ]);
    if (!isSummaryEdit) {
        return (
            <StepButtonContainer>
                {onBack ? (
                    <Button disabled={isDisabledBack} onClick={onBack}>
                        {backText}
                    </Button>
                ) : (
                    <Button disabled>{backText}</Button>
                )}

                <Button type="submit" disabled={isDisabledSubmit}>
                    {submitText}
                </Button>
            </StepButtonContainer>
        );
    }
    return (
        <StepButtonContainer>
            {onBack ? (
                <Button disabled={isDisabledBack || loading} onClick={onBack}>
                    Abbrechen
                </Button>
            ) : (
                <Button disabled>Abbrechen</Button>
            )}

            <Button type="submit" disabled={isDisabledSubmit || loading}>
                Bestätigen
            </Button>
        </StepButtonContainer>
    );
};

export default ThgForm;
