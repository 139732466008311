import React, { ReactNode } from "react";

import { Path, UseFormRegister } from "react-hook-form";

import styled from "@emotion/styled";

import { StyledInput as StyledCheckbox } from "../../../../shared/forms/NewCheckbox";
import { Error } from "../../ThgForm";
import { iThgTermsOfServiceForm } from "../../ThgForm/store";

const Container = styled.div`
    display: flex;
    justify-content: end;
`;
const ErrorContainer = styled.div`
    text-align: right;
`;
const Checkbox = styled(StyledCheckbox)`
    flex: none;
    margin-top: 5px;
    margin-left: 10px;
`;

const Label = styled.label`
    text-align: end;
`;

interface iTermsOfServiceCheckboxProps {
    name: Path<iThgTermsOfServiceForm>;
    register: UseFormRegister<iThgTermsOfServiceForm>;
    error?: any;
    required?: boolean;
    children: HTMLElement | ReactNode;
}

const TermsOfServiceCheckbox = ({
    name,
    register,
    error = "",
    required = false, //Let the backend handle the validation
    children,
}: iTermsOfServiceCheckboxProps) => {
    return (
        <div>
            <Container>
                <Label htmlFor={name}>{children}</Label>
                <Checkbox
                    id={name}
                    type="checkbox"
                    {...register(name, {
                        required: required,
                    })}
                />
            </Container>
            {error && (
                <ErrorContainer>
                    <Error>{error}</Error>
                </ErrorContainer>
            )}
        </div>
    );
};

export default TermsOfServiceCheckbox;
