import create from "zustand";

import { apiFetch, errorAwareApiFetch } from "../../../../apiBindings";
import { consentKeys } from "../../../../utils/consentHelper";
import { mapLaravelErrors } from "../../../../utils/reactHookFormsHelper";
import { storageLocal, storageSession } from "../../../../utils/StorageHandler";

export enum CustomerTypeEnum {
    PRIVATE = "Privatkunde",
    CORPORATE = "Firmenkunde",
}

interface iVehicleClass {
    value: string;
    text: string;
    bonus: string;
    created_at: string;
    updated_at: string;
}

interface iThgCoupon {
    id: number;
    value: number;
    text: string;
    created_at: string;
    updated_at: string;
}

export enum VehicleDriveTypeEnum {
    ELECTRIC = "0004",
}

export enum ThgPartialFormTypesEnum {
    PERSONAL = "personalForm",
    VEHICLE = "vehicleForm",
    BANK = "bankForm",
    TERMS = "termsOfServiceForm",
}

export enum FormStepEnum {
    QR_CODE = 1,
    PERSONAL = 2,
    VEHICLE = 3,
    BANK = 4,
    SUMMARY = 5,
}

export type ThgPartialFormTypes =
    | ThgPartialFormTypesEnum.PERSONAL
    | ThgPartialFormTypesEnum.VEHICLE
    | ThgPartialFormTypesEnum.BANK
    | ThgPartialFormTypesEnum.TERMS;

export type ThgPartialForm =
    | iThgPersonalForm
    | iThgVehicleForm
    | iThgBankForm
    | iThgTermsOfServiceForm;

export interface iThgPersonalForm {
    customerType: CustomerTypeEnum;
    registrationYear: number | null;
    companyName?: string;
    salesTaxId?: string;
    firstname: string;
    lastname: string;
    email: string;
    zipcode: string;
    city: string;
    street: string;
    houseNumber: string;
}

export interface iThgVehicleForm {
    vehicleRegistrationDocumentFront: any;
    vehicleClass: string;
    vehicleOwner: string;
    dateOfApproval: Date | string;
    vehicleIdentificationNumber: string;
    driveMode: string;
    licensePlate: string;
    couponId: string;
}

export interface iThgBankForm {
    paymentRecipient: string;
    iban: string;
}

export interface iThgTermsOfServiceForm {
    termsOfContractAccepted: boolean;
    rightsOfWithdrawalAccepted: boolean;
    dataProtectionAccepted: boolean;
    statutoryDeclarationAccepted: boolean;
}

export interface iMappedBackendError {
    field: any;
    message: string;
}

interface iValidatorResponse {
    step: string;
    isValid: boolean;
    errors: iMappedBackendError[];
}

interface iThgFormStore {
    vehicleClasses: iVehicleClass[];
    coupons: iThgCoupon[];
    loading: boolean;
    currentStep: number;
    isSummaryEdit: boolean;
    personalForm: iThgPersonalForm;
    vehicleForm: iThgVehicleForm;
    bankForm: iThgBankForm;
    termsOfServiceForm: iThgTermsOfServiceForm;
    getVehicleClassByValue: (value: string) => iVehicleClass | undefined;
    getCouponById: (id: string | undefined) => iThgCoupon | undefined;
    fetchVehicleClasses: () => void;
    fetchCoupons: () => void;
    setPartialForm: <k extends ThgPartialFormTypes>(
        key: k,
        data: iThgFormStore[k]
    ) => void;
    resetForm: () => void;
    setCurrentStep: (step: number) => void;
    setIsSummaryEdit: (isEdit: boolean) => void;
    getFullForm: () => any;
    validateForm: (
        formKey: ThgPartialFormTypes,
        data: ThgPartialForm
    ) => Promise<iValidatorResponse>;
    submitForm: () => Promise<iValidatorResponse>;
}

const defaultForm = {
    personalForm: {
        customerType: CustomerTypeEnum.PRIVATE,
        registrationYear: null,
        companyName: "",
        salesTaxId: "",
        firstname: "",
        lastname: "",
        email: "",
        zipcode: "",
        city: "",
        street: "",
        houseNumber: "",
    },
    vehicleForm: {
        vehicleRegistrationDocumentFront: undefined,
        vehicleClass: "",
        vehicleOwner: "",
        dateOfApproval: "",
        vehicleIdentificationNumber: "",
        driveMode: VehicleDriveTypeEnum.ELECTRIC,
        licensePlate: "",
        couponId: "",
    },

    bankForm: {
        paymentRecipient: "",
        iban: "",
    },

    termsOfServiceForm: {
        termsOfContractAccepted: false,
        rightsOfWithdrawalAccepted: false,
        dataProtectionAccepted: false,
        statutoryDeclarationAccepted: false,
    },
};

const useStore = create<iThgFormStore>((set, get) => ({
    vehicleClasses: [],
    coupons: [],
    loading: false,
    currentStep: FormStepEnum.QR_CODE,
    isSummaryEdit: false,
    personalForm: defaultForm.personalForm,
    vehicleForm: defaultForm.vehicleForm,
    bankForm: defaultForm.bankForm,
    termsOfServiceForm: defaultForm.termsOfServiceForm,

    setPartialForm: <k extends ThgPartialFormTypes>(
        key: k,
        data: iThgFormStore[k]
    ) => {
        set({ [key]: data } as { [k_ in k]: iThgFormStore[k_] });
    },

    resetForm: () => {
        set({ personalForm: defaultForm.personalForm });
        set({ vehicleForm: defaultForm.vehicleForm });
        set({ bankForm: defaultForm.bankForm });
        set({ termsOfServiceForm: defaultForm.termsOfServiceForm });
    },

    setCurrentStep: (step) => {
        set({ currentStep: step });
    },

    //Changes the Form Controls if the Form is Summary-Edit-Mode
    setIsSummaryEdit: (isEdit) => {
        set({ isSummaryEdit: isEdit });
    },

    getFullForm: () => {
        const formData = new FormData();
        const forms = [
            get().personalForm,
            get().vehicleForm,
            get().bankForm,
            get().bankForm,
            get().termsOfServiceForm,
        ];

        forms
            .flatMap((form) => Object.entries(form))
            .forEach(([k, v]) => formData.append(k, v));

        // Add metadata about referer links / parameters
        formData.append("urlParams", storageSession.get("app:parameter"));
        formData.append("referrer", storageSession.get("app:referrer"));
        formData.append(
            "consentAnalyse",
            storageLocal.get(consentKeys.analytics)
        );
        formData.append(
            "consentMarketing",
            storageLocal.get(consentKeys.marketing)
        );

        return formData;
    },

    getVehicleClassByValue: (value) => {
        return get().vehicleClasses.find((vehicleClass) => {
            return vehicleClass.value === value;
        });
    },

    getCouponById: (id) => {
        return get().coupons.find((coupon) => {
            return coupon.id === Number(id);
        });
    },

    fetchVehicleClasses: () => {
        apiFetch("/thg/form/vehicle-classes")
            .then((response) => response.json())
            .then(({ data }) => {
                set({ vehicleClasses: data });
            });
    },

    fetchCoupons: () => {
        apiFetch("/thg/form/coupons")
            .then((response) => response.json())
            .then(({ data }) => {
                set({ coupons: data });
            });
    },

    //Sends a Request to validate the partial form on the backend after every step.
    validateForm: async (formKey, data) => {
        const url = `/thg/form/${formKey}/validate`;
        set({ loading: true });

        //Always send Request as formdata because otherwise Uploads are not available.
        const form = new FormData();
        for (const [k, v] of Object.entries(data)) {
            form.append(k, v);
        }

        try {
            return await errorAwareApiFetch(
                url,
                {
                    method: "POST",
                    body: form,
                },
                true
            );
        } catch (error: any) {
            if (error.response.status === 422) {
                return mapLaravelErrors(error);
            }
        } finally {
            set({ loading: false });
        }
    },

    submitForm: async () => {
        set({ loading: true });
        const form = get().getFullForm();
        try {
            return await errorAwareApiFetch(
                "/thg/form/submit",
                {
                    method: "POST",
                    body: form,
                },
                true
            );
        } catch (error) {
            return mapLaravelErrors(error);
        } finally {
            set({ loading: false });
        }
    },
}));

export default useStore;
