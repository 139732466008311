import React, { ReactNode, useEffect, useMemo } from "react";

import dayjs from "dayjs";

import { useForm, useWatch } from "react-hook-form";

import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import useAddressApi from "../../../../../../hooks/useAddressApi";
import ReactHookInput from "../../../../../shared/forms/ReactHookInput";
import ReactHookSelect, {
    SelectOptions,
} from "../../../../../shared/forms/ReactHookSelect";
import NewHeadline from "../../../../../shared/NewHeadline";
import useSnackbar from "../../../../../shared/Snackbar/store";
import { preventEnterButton } from "../../index";
import useStore, {
    CustomerTypeEnum,
    iThgPersonalForm,
    ThgPartialFormTypesEnum,
} from "../../store";

interface iPersonalStep {
    onFormSubmitted: (
        key: ThgPartialFormTypesEnum.PERSONAL,
        data: iThgPersonalForm
    ) => void;
    children: HTMLElement | ReactNode;
}

const RadioContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
`;
const RadioButton = styled.input`
    accent-color: black;
`;

const StreetRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    ${({ theme }) => theme.breakpoints.from.small.css`
       grid-template-columns: 4fr 1fr;
       gap: 10px;
    `}
`;

const CityRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    ${({ theme }) => theme.breakpoints.from.small.css`
       grid-template-columns: 1fr 2fr;
       gap: 10px;
    `}
`;

const getAvailableRegistrationYears = (): SelectOptions<
    iThgPersonalForm,
    "registrationYear"
> => {
    const years = [];
    const current = dayjs().format("YYYY");
    //The current year should only be available until November.
    if (dayjs().month() < 10) {
        years.push({ value: Number(current), text: current });
    }
    // When its October add the coming year to the array:
    if (dayjs().month() >= 9) {
        const nextYear = dayjs().add(1, "year").format("YYYY");
        years.push({ value: Number(nextYear), text: nextYear });
    }

    return years;
};

const PersonalStep = ({ onFormSubmitted, children }: iPersonalStep) => {
    const { notify } = useSnackbar();

    const { personalForm, validateForm } = useStore(
        (state) => ({
            personalForm: state.personalForm,
            validateForm: state.validateForm,
        }),
        shallow
    );

    const {
        handleSubmit,
        control,
        reset,
        getValues,
        setValue,
        register,
        setError,
        formState: { errors },
    } = useForm<iThgPersonalForm>({
        defaultValues: personalForm,
    });

    const customerType = useWatch({
        control,
        name: "customerType",
    });

    const city = useWatch({
        control,
        name: "city",
    });

    const street = useWatch({
        control,
        name: "street",
    });

    const zipcode = useWatch({
        control,
        name: "zipcode",
    });

    const { streets, cities, addressApiError } = useAddressApi({
        zipcode: zipcode,
        city: city,
    });

    useEffect(() => {
        if (addressApiError) {
            const msg =
                addressApiError.message ??
                "Address-Suche fehlgeschlagen. Bitte versuche es erneut.";
            setValue("city", "");
            setValue("street", "");
            void notify("Fehler", msg);
        }
    }, [addressApiError]);

    useEffect(() => {
        if (customerType === CustomerTypeEnum.PRIVATE) {
            reset({ ...getValues(), companyName: "", salesTaxId: "" });
        }
    }, [customerType]);

    const onSubmit = async (data: iThgPersonalForm) => {
        const response = await validateForm(
            ThgPartialFormTypesEnum.PERSONAL,
            data
        );
        if (response.errors.length > 0) {
            response.errors.forEach((error) => {
                setError(error.field, {
                    type: "custom",
                    message: error.message,
                });
            });
            return;
        }
        onFormSubmitted(ThgPartialFormTypesEnum.PERSONAL, data);
    };

    const availableRegistrationYears = useMemo(
        getAvailableRegistrationYears,
        []
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={preventEnterButton}>
            <NewHeadline lookLike={2}>Personenangaben</NewHeadline>

            <ReactHookSelect
                errors={errors.registrationYear}
                name="registrationYear"
                placeholder="Anmeldejahr"
                rules={{ required: true }}
                control={control}
                options={availableRegistrationYears}
            />

            <RadioContainer>
                <RadioButton
                    id="customer-type-private"
                    {...register("customerType")}
                    type="radio"
                    value={CustomerTypeEnum.PRIVATE}
                />
                <label htmlFor="customer-type-private">Privatkunde</label>

                <RadioButton
                    id="customer-type-corporate"
                    {...register("customerType")}
                    type="radio"
                    value={CustomerTypeEnum.CORPORATE}
                />
                <label htmlFor="customer-type-corporate">Firmenkunde</label>
            </RadioContainer>
            {customerType === CustomerTypeEnum.CORPORATE && (
                <>
                    <ReactHookInput
                        name="companyName"
                        placeholder="Firmenname"
                        rules={{ required: true }}
                        control={control}
                        ifNullish=""
                        asUndefined=""
                    />

                    <ReactHookInput
                        name="salesTaxId"
                        placeholder="USt-IdNr."
                        rules={{ required: true }}
                        control={control}
                        ifNullish=""
                        asUndefined=""
                    />
                </>
            )}

            <ReactHookInput
                name="firstname"
                errors={errors?.firstname}
                placeholder="Vorname"
                rules={{ required: true }}
                control={control}
            />

            <ReactHookInput
                name="lastname"
                placeholder="Nachname"
                rules={{ required: true }}
                control={control}
            />

            <ReactHookInput
                type="email"
                name="email"
                placeholder="E-Mail"
                rules={{ required: true }}
                control={control}
            />

            <NewHeadline lookLike={2}>Adresse</NewHeadline>

            <CityRow>
                <ReactHookInput
                    name="zipcode"
                    type="text"
                    placeholder="Suche Postleitzahl"
                    rules={{ required: true }}
                    control={control}
                />

                <ReactHookSelect
                    errors={errors.city}
                    name="city"
                    placeholder="Ort"
                    rules={{ required: true }}
                    control={control}
                    options={cities.map((city) => ({
                        value: city.ONAME,
                        text: city.ONAME,
                    }))}
                />
            </CityRow>

            <StreetRow>
                <ReactHookSelect
                    errors={errors.street}
                    name="street"
                    placeholder="Straße"
                    rules={{ required: true }}
                    control={control}
                    defaultValue={street}
                    options={streets.map((street) => ({
                        value: street,
                        text: street,
                    }))}
                />
                <ReactHookInput
                    name="houseNumber"
                    type="text"
                    placeholder="Hausnummer"
                    rules={{ required: true }}
                    control={control}
                />
            </StreetRow>
            {children}
        </form>
    );
};

export default PersonalStep;
