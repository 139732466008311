import React, { ReactNode } from "react";

import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import qrCodeImage from "../../img/qrcode thg_antrag_prämie.png";
import { preventEnterButton } from "../../index";
import useStore from "../../store";

interface iQrCodeStepProps {
    children: HTMLElement | ReactNode;
}

const QrCodeContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const QrCodeStep = ({ children }: iQrCodeStepProps) => {
    const theme = useTheme();
    const isTablet = theme.breakpoints.from.medium.useMatches();

    const [currentStep, setCurrentStep] = useStore((state) => [
        state.currentStep,
        state.setCurrentStep,
    ]);

    const onSubmit = (event: React.SyntheticEvent<HTMLElement>) => {
        event.preventDefault();
        return setCurrentStep(currentStep + 1);
    };

    return (
        <form onSubmit={onSubmit} onKeyDown={preventEnterButton}>
            <p>
                Nur noch ein paar Schritte bis zu Deiner THG-Prämie. <br />
                Bitte halte Deinen Fahrzeugschein (Zulassungsbescheinigung Teil
                1) und Deine Kontodaten bereit. Was Du unbedingt für Deinen
                Antrag benötigst, ist ein Scan bzw. ein Foto jeweils der Vorder-
                und der Rückseite Deines Fahrzeugscheins. <br />
            </p>
            {isTablet && (
                <QrCodeContainer>
                    <p>
                        Falls Du eine solche Datei noch nicht vorliegen hast,
                        kannst Du jetzt diesen Antrag auch auf Deinem Smartphone
                        ausfüllen und die Fotos des Fahrzeugscheins direkt mit
                        Deiner Kamerafunktion machen. <br />
                        Dazu scanne mit Deinem Smartphone bitte folgenden
                        QR-Code oder öffne in deiner Browser-App folgende
                        Adresse:{" "}
                        <a href="https://www.hoyer.de/thg/antrag-praemie/">
                            https://www.hoyer.de/thg/antrag-praemie/
                        </a>
                    </p>
                    <img
                        title="QR-Code Antrag Prämie"
                        width={300}
                        alt="QR-Code Antrag Prämie"
                        src={qrCodeImage}
                    />
                </QrCodeContainer>
            )}

            {children}
        </form>
    );
};

export default QrCodeStep;
