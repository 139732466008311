import { useEffect, useState } from "react";

import { apiFetch } from "../apiBindings";

/**
 * How to use this hook:
 * 1. include the hook in your component registering the dynamic zipcode variable
 * `const { ... } = useAddressApi({zipcode: var, city: var});`
 *
 * 2. cities are fetched by the given postcode
 *
 * 3. Make the `cities` available for the user to pick by using a Select input.
 *
 * 4. If The User picks a city make sure to call set `setSelectedCity` on this hook
 *
 * 5. After the city is picked the Streets are being fetched
 *
 * 6. Make the `streets` available for the user to pick by using a Select input.
 *
 * 7. Use `hasAddressApiError` to see is any errors happened on the address API
 *
 */

interface iAddressCity {
    ONAME: string;
    OZUSATZ: string;
    ALORT: string;
    "FULL-NAME": string; //yikes, who uses hyphens in API response keys?
}

interface iuseAddressApiProps {
    zipcode: string;
    city: string;
}

interface iAddressApiError {
    message: string;
}

export default function useAddressApi({
    zipcode = "",
    city = "",
}: iuseAddressApiProps) {
    const [streets, setStreets] = useState<string[]>([]);
    const [cities, setCities] = useState<iAddressCity[]>([]);
    const [addressApiError, setAddressApiError] =
        useState<iAddressApiError | null>(null);

    useEffect(() => {
        if (zipcode && isValidZipcode(zipcode)) {
            setAddressApiError(null);
            setCities([]);
            const fetch = async () => {
                try {
                    const cities = await fetchCitiesByZipcode(zipcode);
                    setCities(cities);
                } catch (error: any) {
                    setAddressApiError(error);
                }
            };
            void fetch();
        }
    }, [zipcode]);

    useEffect(() => {
        if (city && zipcode && isValidZipcode(zipcode)) {
            setAddressApiError(null);
            setStreets([]);
            const fetch = async () => {
                try {
                    const streets = await fetchStreetsByZipcodeAndCity(
                        zipcode,
                        city
                    );
                    setStreets(streets);
                } catch (error: any) {
                    setAddressApiError(error);
                }
            };
            void fetch();
        }
    }, [city, zipcode]);

    return { streets, cities, addressApiError };
}

const fetchCitiesByZipcode = async (
    zipcode: string
): Promise<iAddressCity[]> => {
    const response = await apiFetch(
        `/address-search/zipcode/${zipcode}/cities`
    );
    if (response.ok) {
        return response.json();
    }
    /*if (response.status >= 400 && response.status < 500) {
        return [];
    }*/
    throw await response.json();
};

const fetchStreetsByZipcodeAndCity = async (
    zipcode: string,
    cityName: string
): Promise<string[]> => {
    const response = await apiFetch(
        `/address-search/zipcode/${zipcode}/city/${cityName}/streets`
    );
    if (response.ok) {
        return response.json();
    }
    /*if (response.status >= 400 && response.status < 500) {
        return [];
    }*/
    throw await response.json();
};

export const isValidZipcode = (zipcode: string) => {
    const regex = new RegExp("^[0-9]{5}$");
    const result = zipcode.match(regex);
    return result && result.length > 0;
};
