import React, { ReactNode, useEffect } from "react";

import { Controller, useForm, useWatch } from "react-hook-form";

import styled from "@emotion/styled";

import DatePicker from "../../../../../shared/forms/DatePicker";
import ReactHookInput from "../../../../../shared/forms/ReactHookInput";
import ReactHookSelect from "../../../../../shared/forms/ReactHookSelect";
import NewHeadline from "../../../../../shared/NewHeadline";
import AlertBox from "../../../../Hcm/components/AlertBox";
import ImageUploadWithPreview from "../../../components/ImageUploadWithPreview";
import ThgBonusAlert from "../../../components/ThgBonusAlert";
import { Error, preventEnterButton } from "../../index";
import useStore, {
    iThgVehicleForm,
    ThgPartialFormTypesEnum,
} from "../../store";

interface iVehicleStepProps {
    onFormSubmitted: (
        key: ThgPartialFormTypesEnum.VEHICLE,
        data: iThgVehicleForm
    ) => void;
    children: HTMLElement | ReactNode | undefined;
}

const AlertContainer = styled.div`
    font-size: large;
`;

const DisclaimerText = styled.div`
    margin-top: 1.5rem;
    font-size: 0.75rem;
`;

const VehiclesStep = ({ onFormSubmitted, children }: iVehicleStepProps) => {
    const [
        vehicleForm,
        validateForm,
        fetchVehicleClasses,
        vehicleClasses,
        fetchCoupons,
        coupons,
    ] = useStore((state) => [
        state.vehicleForm,
        state.validateForm,
        state.fetchVehicleClasses,
        state.vehicleClasses,
        state.fetchCoupons,
        state.coupons,
    ]);

    const {
        handleSubmit,
        control,
        reset,
        getValues,
        setError,
        formState: { errors },
    } = useForm<iThgVehicleForm>({
        defaultValues: vehicleForm,
    });

    useEffect(() => {
        if (vehicleClasses.length === 0) {
            fetchVehicleClasses();
        }
        if (coupons.length === 0) {
            fetchCoupons();
        }
    }, []);

    const onSubmit = async (data: iThgVehicleForm) => {
        const response = await validateForm(
            ThgPartialFormTypesEnum.VEHICLE,
            data
        );

        if (response.errors.length > 0) {
            response.errors.forEach((error) => {
                setError(error.field, {
                    type: "custom",
                    message: error.message,
                });
            });
            return;
        }
        onFormSubmitted(ThgPartialFormTypesEnum.VEHICLE, data);
    };

    const resetFile = (event: React.SyntheticEvent, field: string) => {
        event.preventDefault();
        reset({ ...getValues(), [field]: undefined });
    };

    const vehicleRegistrationDocumentFront = useWatch({
        control,
        name: "vehicleRegistrationDocumentFront",
    });

    const vehicleClass = useWatch({
        control,
        name: "vehicleClass",
    });

    const couponId = useWatch({
        control,
        name: "couponId",
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={preventEnterButton}>
            <NewHeadline lookLike={2}>Fahrzeugangaben</NewHeadline>

            <AlertContainer>
                <AlertBox>
                    Achtung! Bitte alle Daten exakt wie auf der
                    Zulassungsbescheinigung Teil 1 angeben!
                </AlertBox>
            </AlertContainer>

            <ImageUploadWithPreview
                name="vehicleRegistrationDocumentFront"
                instructionText="jpeg, png, maximal 4 MB"
                title="Bild vom Fahrzeugschein (Vorderseite)"
                control={control}
                uploadedFile={vehicleRegistrationDocumentFront}
                onResetFile={(event) =>
                    resetFile(event, "vehicleRegistrationDocumentFront")
                }
            />
            {errors && errors.vehicleRegistrationDocumentFront && (
                <Error>{errors.vehicleRegistrationDocumentFront.message}</Error>
            )}

            <ReactHookSelect
                name="vehicleClass"
                placeholder="Fahrzeugklasse (Feld J)"
                rules={{ required: true }}
                control={control}
                options={vehicleClasses ?? []}
            />

            <ReactHookInput
                name="vehicleOwner"
                placeholder="Fahrzeuginhaber (Feld C.1.1 und C.1.2)"
                rules={{ required: true }}
                control={control}
            />

            <Controller
                control={control}
                name="dateOfApproval"
                render={({ field }) => (
                    <>
                        <DatePicker
                            label="Datum der Zulassung (Feld I)"
                            showMonthDropdown={true}
                            showYearDropdown={true}
                            dropdownMode="select"
                            openToDate={new Date()}
                            {...field}
                        />
                    </>
                )}
            />
            {errors && errors.dateOfApproval && (
                <Error>{errors.dateOfApproval.message}</Error>
            )}

            <ReactHookInput
                name="vehicleIdentificationNumber"
                placeholder="Fahrzeugidentifikationsnummer (Feld E)"
                rules={{ required: true }}
                control={control}
            />

            <ReactHookSelect
                name="driveMode"
                placeholder="Antriebsart (Feld 10)"
                rules={{ required: true }}
                control={control}
                options={[{ value: "0004", text: "0004" }]}
            />

            <ReactHookInput
                name="licensePlate"
                placeholder="Kennzeichen"
                rules={{ required: true }}
                control={control}
            />

            <ReactHookSelect
                name="couponId"
                placeholder="Gutschein"
                rules={{ required: true }}
                control={control}
                options={
                    coupons.map((coupon) => ({
                        value: coupon.value.toString(),
                        text: coupon.text + "*",
                    })) ?? []
                }
            />

            <ThgBonusAlert vehicleClass={vehicleClass} couponId={couponId} />

            {children}

            <DisclaimerText>
                * Der Gutschein hat eine Gültigkeitsdauer von drei Jahren ab
                Antragstellung und wird zusammen mit der Prämie ausgegeben. Der
                Hoyer Heizöl Gutschein Code ist nur online einlösbar. Der Hoyer
                Pellet Gutschein wird als Gutschrift auf dem Kundenkonto
                vermerkt. Die Hoyer Tankstellen Gutscheinkarte wird per Post an
                die angegebene Adresse versendet und ist an allen teilnehmenden
                Hoyer Tankstellen einlösbar.
            </DisclaimerText>
        </form>
    );
};

export default VehiclesStep;
