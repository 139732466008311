import React from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";

import useStore from "../../ThgForm/store";

interface iThgBonusAlertProps {
    vehicleClass?: string;
    couponId?: string;
    textOnly?: boolean;
}

const BonusBox = styled.div`
    font-size: large;
    ${({ theme }) => css`
        color: ${theme.colors.red.toString()};
        border: 1px solid ${theme.colors.red.toString()};
    `}

    border-radius: 4px;
    padding: 10px;
`;

const ThgBonusAlert = ({
    vehicleClass = "",
    couponId = undefined,
    textOnly = false,
}: iThgBonusAlertProps) => {
    const [getVehicleClassByValue, getCouponById] = useStore((state) => [
        state.getVehicleClassByValue,
        state.getCouponById,
    ]);
    const selectedVehicleClass = getVehicleClassByValue(vehicleClass);
    const selectedCouponText = getCouponById(couponId)?.text;

    if (!selectedVehicleClass || !selectedCouponText) {
        return null;
    }
    const bonusText = `Für Deine ausgewählte Fahrzeugklasse '${selectedVehicleClass?.text}' erhältst Du die folgende
                Prämie`;
    if (textOnly) {
        return (
            <span>
                {selectedVehicleClass?.bonus} + {selectedCouponText}.
            </span>
        );
    }

    return (
        <BonusBox>
            {bonusText}: {selectedVehicleClass?.bonus} + {selectedCouponText}*.
        </BonusBox>
    );
};

export default ThgBonusAlert;
