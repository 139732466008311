import React, { ReactNode } from "react";

import { useForm } from "react-hook-form";

import ReactHookInput from "../../../../../shared/forms/ReactHookInput";
import NewHeadline from "../../../../../shared/NewHeadline";
import { preventEnterButton } from "../../index";
import useStore, { iThgBankForm, ThgPartialFormTypesEnum } from "../../store";

interface iBankStep {
    onFormSubmitted: (
        key: ThgPartialFormTypesEnum.BANK,
        data: iThgBankForm
    ) => void;
    children: HTMLElement | ReactNode;
}

const BankStep = ({ onFormSubmitted, children }: iBankStep) => {
    const [bankForm, validateForm] = useStore((state) => [
        state.bankForm,
        state.validateForm,
    ]);
    const { handleSubmit, control, setError } = useForm<iThgBankForm>({
        defaultValues: bankForm,
    });

    const onSubmit = async (data: iThgBankForm) => {
        const response = await validateForm(ThgPartialFormTypesEnum.BANK, data);
        if (response.errors.length > 0) {
            response.errors.forEach((error) => {
                setError(error.field, {
                    type: "custom",
                    message: error.message,
                });
            });
            return;
        }

        onFormSubmitted(ThgPartialFormTypesEnum.BANK, data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={preventEnterButton}>
            <NewHeadline lookLike={2}>Kontodaten</NewHeadline>
            <ReactHookInput
                name="paymentRecipient"
                placeholder="Zahlungsempfänger"
                rules={{ required: true }}
                control={control}
            />

            <ReactHookInput
                name="iban"
                placeholder="IBAN"
                rules={{ required: true }}
                control={control}
            />
            {children}
        </form>
    );
};

export default BankStep;
