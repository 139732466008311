import React from "react";

import { graphql } from "gatsby";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import ThgForm from "../../../components/pages/Thg/ThgForm";
import Container from "../../../components/shared/Container";
import NewHeadline from "../../../components/shared/NewHeadline";
import Page from "../../../components/shared/Page";

const ThgPage = () => {
    const theme = useTheme();
    const additionalStyles = css`
        font-family: var(--font-family-vito);
        color: ${theme.colors.blue.toString()};
    `;
    const FormContainer = styled.div`
        padding-top: 20px;
        margin-top: 20px;
    `;
    return (
        <Page>
            <Container slim css={additionalStyles}>
                <NewHeadline is={1} lookLike={1}>
                    THG-Antrag
                </NewHeadline>
                <FormContainer>
                    <ThgForm />
                </FormContainer>
            </Container>
        </Page>
    );
};

export default ThgPage;

//Load the THG Footer for this page
export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "thg" }) {
            footer {
                ...Footer
            }
        }
    }
`;
