import React from "react";

import styled from "@emotion/styled";

import { ReactHookSingleImageUpload } from "../../../../shared/forms/SingleImageUpload";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";

interface iFileUploadWithPreviewProps {
    control: any;
    uploadedFile: any;
    name: string;
    title: string;
    instructionText?: string;
    onResetFile: (event: React.SyntheticEvent) => void;
}

const FileUploadContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 25px 0;
`;

const ImageUploadWithPreview = ({
    control,
    uploadedFile,
    name,
    onResetFile,
    title,
    instructionText,
    ...props
}: iFileUploadWithPreviewProps) => {
    return (
        <FileUploadContainer>
            {!uploadedFile && (
                <ReactHookSingleImageUpload
                    control={control}
                    name={name}
                    instructionText={instructionText}
                    title={`${title} hochladen`}
                    {...props}
                />
            )}

            {uploadedFile && (
                <>
                    <div>
                        <NewHeadline lookLike={4}>{title}</NewHeadline>
                        <img
                            width={200}
                            alt="Upload Dokument Vorschau"
                            src={URL.createObjectURL(uploadedFile)}
                        />
                    </div>

                    <div>
                        <p>Name: {uploadedFile.name}</p>
                        <p>Größe: {(uploadedFile.size / 1024).toFixed(2)} KB</p>

                        <Button color="redOutline" onClick={onResetFile} small>
                            Dokument entfernen
                        </Button>
                    </div>
                </>
            )}
        </FileUploadContainer>
    );
};

export default ImageUploadWithPreview;
