import React from "react";

import { Control, Controller, FieldValues } from "react-hook-form";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import Headline from "../../NewHeadline";

interface iSingleImageUploadProps {
    name: string;
    onChange: (image: any) => any;
    title?: string;
    accept?: string;
    instructionText?: string;
}

const FileInput = styled.input`
    ::file-selector-button {
        font-weight: 700;
        font-size: 1rem;
        margin-right: 20px;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        ${({ theme }) => css`
            color: ${theme.colors.white.toString()};
            background: ${theme.colors.red.toString()};
        `}
    }
`;

const InstructionText = styled.p`
    font-size: small;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const SingleImageUpload = ({
    name,
    onChange,
    title = "Datei hochladen",
    accept = "image/*",
    instructionText = "",
}: iSingleImageUploadProps) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : undefined;
        onChange(file);
    };
    return (
        <Container>
            <div>
                <Headline lookLike={4}>{title}</Headline>
                <FileInput
                    name={name}
                    onChange={handleFileChange}
                    type="file"
                    accept={accept}
                />
                <br />
                {instructionText && (
                    <InstructionText>{instructionText}</InstructionText>
                )}
            </div>
        </Container>
    );
};

interface iReactHookSingleImageUploadProps {
    control: Control<FieldValues, any> | any;
    name: string;
    title?: string;
    instructionText?: string;
    accept?: string;
}
// Upload integrating React-Hook-Form
export const ReactHookSingleImageUpload = ({
    control,
    name,
    title = "Datei hochladen",
    instructionText = "",
    accept = "image/*",
}: iReactHookSingleImageUploadProps) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <>
                    <SingleImageUpload
                        instructionText={instructionText}
                        accept={accept}
                        title={title}
                        {...field}
                    />
                </>
            )}
        />
    );
};

export default SingleImageUpload;
