import React from "react";

import styled from "@emotion/styled";

const AlertBoxStyle = styled.div`
    padding: 10px;
    color: ${({ theme }) => theme.colors.white.toString()};
    background-color: ${({ theme }) => theme.colors.red.toString()};

    span {
        font-weight: bold;
    }
`;

interface iAlertBoxProps {
    children: React.ReactNode;
}

const AlertBox = ({ children }: iAlertBoxProps) => {
    return <AlertBoxStyle>{children}</AlertBoxStyle>;
};

export default AlertBox;
