export const mapLaravelErrors = (laravelFormError) => {
    const data = laravelFormError?.data;
    if (!data.errors) {
        return data;
    }
    const errors = Object.keys(data?.errors).map((item) => ({
        field: item,
        message: data.errors[item].join(" "),
    }));

    return { ...data, errors: errors };
};
